import { useEffect, useState, useCallback, useRef, useContext } from "react";
import ReactDOMServer from "react-dom/server";
import { useNavigate } from "react-router-dom";

// components
import Loading from "../../components/Loading/Loading";
import { fetchData } from "../../services/apiService";
import { NormalizeCategory, HideCategory } from "../../components/FilterCategory/FilterCategory.js";
import { FilterContext } from "../../components/FilterCategory/FilterContext.js";

// resources
import sliderControl from "../../assets/img/svg/arrow_slider_white.svg";

// styles
import "../../assets/css/components/gantt.css";
import "../../assets/css/components/loading.css";

const months = [
  "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
  "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
];

let
  weeksInYear = 0
;

const Gantt = ({ params, categorySelected, seasonSelected, historyActivityID, setHistoryActivityID, onCategorySelected}) => {
  const { setSelectedFilter } = useContext(FilterContext);

  const
    [ganttDataIndex, setGanttDataIndex] = useState(0),
    [campaignArrays, setCampaignArrays] = useState([]),
    [activityElements, setActivityElements] = useState([]),
    [titleMultiGantt, setTitleMultiGantt] = useState(""),
    [existingCategories, setExistingCategories] = useState(new Set()),
    [ganttData, setGanttData] = useState(null),
    [dateData, setDateData] = useState(null),
    [rowCounts, setRowCounts] = useState({}),

    isMounted = useRef(false),
    containerGanttRef = useRef(null),
    navigate = useNavigate()
  ;

  const fetchDataDate = useCallback(async () => {
    const url_date = "/api/gantt/data";

    try {
      const response = await fetchData(url_date);
      setDateData(response);
      weeksInYear = Math.max(...response.data.Diciembre);
    } catch(error) {
      console.error(error);
    }
  }, []);


  const fetchDataGantt = useCallback(async () => {
    const url_gantt = `/api/gantt/${params.idSegment}`;

    try {
      const ganttDataFetch = await fetchData(url_gantt);
      console.dir(ganttDataFetch);

      if (!ganttDataFetch.data || ganttDataFetch.data.length === 0) {
        navigate("/404");
        return;
      } else {
        setGanttData(ganttDataFetch);
      }

      const multiGanttElement = document.querySelector(".multi_gantt");
      if (ganttDataFetch.data.length > 1) {
        multiGanttElement.classList.remove("hide");
      } else {
        multiGanttElement.classList.add("hide");
        setGanttDataIndex(0);
      }

      function createCampaignArray(ganttDataFetch) {
        const allCampaignArrays = [];
        const allCampaignMatrix = [];
        const rowCountMap = {};

        const ganttDataItem = ganttDataFetch.data?.[ganttDataIndex];
        if (!ganttDataItem) { return; }
        setTitleMultiGantt(ganttDataItem.calendar || "WHS");

        ganttDataFetch.data[ganttDataIndex].data_calendar.forEach(activity => {
          activity.type_campaigns.forEach(typeCampaign => {
            let campaignArrays = [];
            let campaignMatrix = [];
            let campaignCounter = 1;
            let typeCampaignId = typeCampaign.id;

            typeCampaign.campaigns.forEach(campaign => {
              let startWeek = parseInt(campaign.date_start);
              let endWeek = parseInt(campaign.date_end);
              let duration = endWeek - startWeek + 1;
              let bg = campaign.category.color;
              let categoryId = campaign.category.id;
              let categoryName = campaign.category.name;
              let seasonId = campaign.season.id;
              let link_campaing = campaign.link_toolkit;

              let addedToExistingArray = false;

              for (let i = 0; i < campaignArrays.length; i++) {
                let row = campaignArrays[i];
                let matrixRow = campaignMatrix[i];
                if (canPlaceCampaign(matrixRow, startWeek, endWeek)) {
                  fillCampaign(
                    row,
                    matrixRow,
                    startWeek,
                    endWeek,
                    campaign.title,
                    duration,
                    bg,
                    categoryId,
                    seasonId,
                    categoryName,
                    campaignCounter,
                    typeCampaignId,
                    link_campaing
                  );
                  addedToExistingArray = true;
                  break;
                }
              }

              if (!addedToExistingArray) {
                let newRow = new Array(weeksInYear).fill(null);
                let newMatrixRow = new Array(weeksInYear).fill(0);
                fillCampaign(
                  newRow,
                  newMatrixRow,
                  startWeek,
                  endWeek,
                  campaign.title,
                  duration,
                  bg,
                  categoryId,
                  seasonId,
                  categoryName,
                  campaignCounter,
                  typeCampaignId,
                  link_campaing
                );
                campaignArrays.push(newRow);
                campaignMatrix.push(newMatrixRow);
              }

              campaignCounter++;
            });

            rowCountMap[typeCampaignId] = (rowCountMap[typeCampaignId] || 0) + campaignArrays.length;

            allCampaignArrays.push(...campaignArrays);
            allCampaignMatrix.push(...campaignMatrix);
          });
        });

        setRowCounts(rowCountMap);
        setCampaignArrays(allCampaignArrays);
      }

      function canPlaceCampaign(matrixRow, start, end) {
        for (let i = start; i <= end; i++) {
          if (matrixRow[i - 1] !== 0) return false;
        }
        return true;
      }

      const handleShowTooltip = (e) => {
        const button = e.currentTarget;
        const tooltip = button.querySelector(".tooltip_campaign");
        const grow = button.offsetParent.offsetParent;
        const prow = grow.parentElement;

        if (!tooltip) return;

        document.querySelectorAll(".tooltip_campaign").forEach(element => element.classList.add("hide"));
        document.querySelectorAll(".wrapper_gantt .active_tooltip").forEach(element => element.classList.remove("active_tooltip"));

        const rowGanttElements = document.querySelectorAll(".wrapper_gantt .row_gantt");
        const isPRowInFirstFour = Array.from(rowGanttElements).indexOf(prow) < 4;
        tooltip.classList.toggle("top", !isPRowInFirstFour);

        let count = 0;
        const isButtonInCamp = [...grow.children].some(row => {
          count++;
          return [...row.children].some(week => {
            return [...week.children].some(camp => {
              if (button === camp) {
                tooltip.classList.toggle("left", count < 4);
                tooltip.classList.toggle("right", count > 9);
                return true; // Se encontró el botón
              }
              return false; // No se encontró el botón
            });
          });
        });

        if (isButtonInCamp) {
          tooltip.classList.remove("hide");
          grow.classList.add("active_tooltip");

          setTimeout(() => {
            tooltip.classList.add("hide");
          }, 10000);
        }
      };

      const truncateString = (str, num) =>
        str.length > num ? str.slice(0, num > 3 ? num - 3 : num) + '...' : str;

      function fillCampaign(
        row,
        matrixRow,
        start,
        end,
        campaignTitle,
        duration,
        bg,
        categoryId,
        seasonId,
        categoryName,
        campaignCounter,
        typeCampaignId,
        link_campaing
      ) {
        const mostrarTextoTruncado = campaignTitle.length > duration * 10;

        setExistingCategories(prevSet => new Set(prevSet).add(NormalizeCategory(categoryName)));

        row[start - 1] = (
          <>
            <button
              className={`campaign cat_${categoryId} ${NormalizeCategory(categoryName)} season_${seasonId} campaign_${typeCampaignId}`}
              style={{
                width: `calc(${duration} * 50px)`,
                backgroundColor: bg
              }}
              onMouseEnter={handleShowTooltip}
              onMouseLeave={() => {
                const tooltip = document.querySelector(".tooltip_campaign");
                if (tooltip) {
                  tooltip.classList.add("hide");
                }
              }}
              onClick={() => openToolkit(link_campaing)}
            >
              {mostrarTextoTruncado ? (
                <>
                  {truncateString(campaignTitle, (duration * 10))}
                  <div className="tooltip top hide tooltip_campaign">
                    {campaignTitle}
                  </div>
                </>
              ) : (
                campaignTitle
              )}
            </button>

          </>
        );
        for (let i = start; i <= end; i++) {
          matrixRow[i - 1] = campaignCounter;
        }
      }

      createCampaignArray(ganttDataFetch);
    } catch (error) {
      console.error("Error fetching Data:", error)
    }
  }, [params, ganttDataIndex, navigate]);

  const renderExtraRow = (rowIndex) => {
    const
      rowCampaign = campaignArrays[rowIndex],
      typeCampaignClass = rowCampaign.find((element) => element !== null)?.props.children.props.className,
      match = typeCampaignClass ? typeCampaignClass.match(/campaign_(\d+)/g) : null,
      typeCampaignId = match ? match[match.length - 1].split("_").pop() : null
    ;

    const activity = ganttData.data[ganttDataIndex].data_calendar.find(activity =>
      activity.type_campaigns.some(tc => tc.id === typeCampaignId)
    );

    const activityId = activity ? activity.id : "undefined";

    return (
      <div className={`row_gantt type_campaign_${typeCampaignId} activity_${activityId}`} key={rowIndex}>
        <div className="group_extra_row">
          {months.map((month) => (
            <div key={month} className="group_extra_week">
              {dateData.data[month].map((week, index) => (
                <div key={index} className="extra_week_element">
                  {campaignArrays[rowIndex][week - 1]}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const extractActivities = useCallback(() => {
    if (ganttData !== null) {
      const activity_ids = [];
      const activities = ganttData.data[ganttDataIndex].data_calendar.map(activityData => {
        const activityElement = (
          <div key={activityData.id} className="activity">
            {activityData.activity}
          </div>
        );

        const typeCampaignsElements = activityData.type_campaigns.map(typeCampaign => (
          <div
            key={typeCampaign.id}
            className={`type_campaigns type_campaigns_${typeCampaign.id}`}
            style={{
              height: `calc(50px * ${rowCounts[typeCampaign.id] || 1})`
            }}
          >
            {typeCampaign.type_campaign}
          </div>
        ));

        activity_ids.push(activityData.id);


        return (
          <div key={activityData.id + "-container"} className={`group_activity activity_${activityData.id}`}>
            {activityElement}
            <div className="group_type_campigns">
              {typeCampaignsElements}
            </div>
          </div>
        );
      });

      setActivityElements(activities);
    }
  }, [ganttData, rowCounts, ganttDataIndex]);

  const clearRowDelete = () => {
    const clearRows = document.querySelectorAll(".row_gantt.clear_row_gantt");
    clearRows.forEach(row => row.remove());
  };

  const handleNextGantt = () => {
    const nextIndex = ganttDataIndex + 1 >= ganttData.data.length ? 0 : ganttDataIndex + 1;
    setGanttDataIndex(nextIndex);
    setTitleMultiGantt(ganttData.data[nextIndex].calendar);
    setHistoryActivityID(new Set());
    setSelectedFilter("");
    onCategorySelected(null);
  };

  const handlePrevGantt = () => {
    const prevIndex = ganttDataIndex - 1 < 0 ? ganttData.data.length - 1 : ganttDataIndex - 1;
    setGanttDataIndex(prevIndex);
    setTitleMultiGantt(ganttData.data[prevIndex].calendar);
    setHistoryActivityID(new Set());
    setSelectedFilter("");
    onCategorySelected(null);
  };

  const openToolkit = (link_campaing) => {
    if (link_campaing !== "") {
      window.open(link_campaing, "_blank");
    }
  }

  /*
   * Se ejecuta cuando el componente se monta por primera vez.
   * Llama a las funciones `fetchDataDate` y `fetchDataGantt`
   * obtiene: las fechas y los datos del Gantt
   */
  useEffect(() => {
    fetchDataDate();
    fetchDataGantt();
  }, [fetchDataGantt, fetchDataDate]);

  /*
   * Se activa cuando `ganttData`, `extractActivities` o `ganttDataIndex` cambian.
   * Llama a la función `extractActivities` para actualizar el estado `activityElements`
   * con las actividades extraídas de los datos del Gantt
   */
  useEffect(() => {
    extractActivities();
  }, [ganttData, extractActivities, ganttDataIndex]);

  /*
   * Se ejecuta cuando `campaignArrays` cambia.
   * Busca todos los elementos de campaña y determina cuál tiene el desplazamiento más
   * a la izquierda. Luego, establece el desplazamiento del contenedor del Gantt
   * para asegurarse de que la primera campaña visible esté alineada a la izquierda
   */
  useEffect(() => {
    const campaignElements = document.querySelectorAll(".campaign");
    let minOffsetLeft = Infinity;

    campaignElements.forEach((campaign) => {
      const offsetLeft = campaign.parentElement.offsetLeft;
      if (offsetLeft < minOffsetLeft) {
        minOffsetLeft = offsetLeft;
      }
    });

    if (minOffsetLeft !== Infinity && containerGanttRef.current) {
      containerGanttRef.current.scrollLeft = minOffsetLeft;
    }
  }, [campaignArrays]);

  /*
   * Se activa cuando `categorySelected` cambia.
   * Se encarga de mostrar u ocultar las campañas según la categoría seleccionada.
   * Si no hay categoría seleccionada, muestra todas las campañas.
   */
  useEffect(() => {
    const allElements = document.querySelectorAll(".campaign");
    const filterElements = document.querySelectorAll(`.cat_${categorySelected}`);

    if (categorySelected !== null) {
      allElements.forEach((element) => {
        element.classList.add("no_visible");
      });

      filterElements.forEach((element) => {
        element.classList.remove("no_visible");
      });
    } else {
      allElements.forEach((element) => {
        element.classList.remove("no_visible");
      });
    }
  }, [categorySelected])

  /*
   * Este se activa cuando `seasonSelected` cambia y filtra las campañas según la temporada seleccionada.
   * Si no hay temporada seleccionada, muestra todas las campañas.
   */
  useEffect(() => {
    const allElements = document.querySelectorAll(".campaign");
    const seasonElements = document.querySelectorAll(`.season_${seasonSelected}`);

    if (seasonSelected !== null) {
      allElements.forEach((element) => {
        element.classList.add("no_visible");
      });

      seasonElements.forEach((element) => {
        element.classList.remove("no_visible");
      });
    } else {
      allElements.forEach((element) => {
        element.classList.remove("no_visible");
      });
    }
  }, [seasonSelected])

  /*
   * Se encarga de ajustar el estilo de los elementos que tienen la clase `row_gantt`
   * al agregar un margen superior de `2px`. Esto solo se aplica a la primera
   * aparición de cada actividad
   */
  useEffect(() => {
    const
      rowGanttElements = document.querySelectorAll(".row_gantt"),
      c1 = "first"
    ;

    rowGanttElements.forEach((element) => {
      const activityClass = [...element.classList].find((className) =>
        className.startsWith("activity_")
      );

      if (activityClass) {
        const seenActivities = document.querySelectorAll(`.row_gantt.${activityClass}`);

        for (const row of seenActivities) row.classList.remove(c1);
        seenActivities[0].classList.add(c1);
      }
    });
  }, [campaignArrays]);

    /*
   * Se asegura de que cada actividad tenga al menos tres filas (`row_gantt`) representadas en el DOM.
   * Si hay menos de tres, agrega filas adicionales utilizando `renderExtraRowClean` para rellenar el espacio.
   */
  useEffect(() => {
    const renderExtraRowClean = (activityId) => {
      return (
        <div className={`row_gantt clear_row_gantt activity_${activityId}`} key={`empty_${activityId}`}>
        <div className="group_extra_row">
        {months.map((month) => (
          <div key={`clean_month_${month}`} className="group_extra_week">
          {dateData.data[month].map((week, index) => (
            <div key={`clean_week_${week}`} className="extra_week_element">
            &nbsp;
            </div>
          ))}
          </div>
        ))}
        </div>
        </div>
      );
    };

    clearRowDelete();
    const activityID = [...historyActivityID];
    if (activityID) {
      for (const id of activityID) {
        const seenActivities = document.querySelectorAll(`.row_gantt.activity_${id}`);

        if (seenActivities.length < 3 && seenActivities.length !== 0) {
          const timesToRender = 3 - seenActivities.length;

          for (let i = 0; i < timesToRender; i++) {
            const
            extraRow = renderExtraRowClean(id),
              extraRowHtml = ReactDOMServer.renderToStaticMarkup(extraRow)
            ;

            if (extraRowHtml) {
              seenActivities[seenActivities.length - 1].insertAdjacentHTML("afterend", extraRowHtml);
            }
          }
        }
      }
    }
  }, [historyActivityID, dateData]);

  /* se ejecuta al términar el renderizado del componente */
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      setHistoryActivityID(new Set());
      return;
    }

    const rows = document.querySelectorAll(`.row_gantt[class*=activity_]`);
    let newHistory = new Set(historyActivityID);

    for (const row of rows) {
      const classList = row.className;
      const match = classList.match(/activity_(\d+)/);

      if (match) {
        const activityId = match[1];
        newHistory.add(activityId);

        if (newHistory.size !== historyActivityID.size) {
          setHistoryActivityID(prev => new Set(prev).add(activityId));
        }
      }
    }

    HideCategory(existingCategories);
  });

  // Loading
  if (!dateData) { return ( <Loading /> ); }

  return (
    <div className="container_main_gantt">
      <div className="multi_gantt hide">
        <p className="description">
          Explora y evalúa los calendarios disponibles para {sessionStorage.getItem("segment_title_now")}
        </p>
        <div className="control_multi_gantt">
          <button onClick={() => handlePrevGantt()} className="prev btn">
            <img src={sliderControl} alt="Previous Campaign" className="img" />
          </button>
          <h3 className="title_multi_gantt">
            {titleMultiGantt}
          </h3>
          <button onClick={() => handleNextGantt()} className="next btn">
            <img src={sliderControl} alt="Next Campaign" className="img" />
          </button>
        </div>
      </div>

      <section className="container_gantt" ref={containerGanttRef}>
        <div className="container_title_gantt">
          <header className="leyends_title layout_2_j">
            <div className="lt1">
              Actividad
            </div>
            <div className="lt2">
              Tipo de campaña
            </div>
          </header>
          {activityElements}
        </div>

        <div className="wrapper_gantt">
          <div className="row_gantt header_gantt">
            {months.map((month) => (
              <div key={month} className="group_title_gantt">
                <h2 className="title_month">{month}</h2>
                <div className="group_week">
                  {dateData.data[month].map((week) => (
                    <div key={week} className="week_element">
                      {week}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>

          {campaignArrays.map((_, rowIndex) => renderExtraRow(rowIndex))}
        </div>
      </section>
    </div>
  );
}

export default Gantt;
