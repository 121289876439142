import React from "react";

// resources
import download from "../../assets/img/svg/download.svg";

// styles
import "../../assets/css/components/type_campaing_card.css";

const TypeCampaingCard = ({
  title,
  campaings,
  special = false
}) => {
  const openToolkit = (campaing) => {
    if (campaing.link_toolkit !== "" && campaing.link_toolkit) {
      window.open(campaing.link_toolkit, "_black");
    }
  }

  return (
    <div className="moments_container">
      <div className="card_moments">
        <h4 className="title_card">{title}</h4>
        <div className="list_item">
          {campaings.map((campaing) => (
            <button
              className={`item ${campaing.link_toolkit ? 'link' : ''}`}
              onClick={() => openToolkit(campaing)}
              key={campaing.id}
            >
              {!special && (
                <div
                  className="shape"
                  style={{ backgroundColor: campaing.category.color }}
                ></div>
              )}
              <p className="item_name">{campaing.title}</p>
              <picture className="download_icon">
                <img src={download} alt="Download" className="download_img" />
              </picture>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TypeCampaingCard;
