import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";

// Pages
import Login from "./pages/Login/Login.js";
import Holding from "./pages/Holding/Holding.js";
import NavigationGuide from "./pages/NavigationGuide/NavigationGuide";
import WHS from "./pages/WHS/WHS";
import SLA from "./pages/SLA/SLA";
import Examples from "./pages/Examples/Examples";
import NotFound from "./pages/NotFound/NotFound";

/* Estilos globales solo en main.scss */
import "./assets/css/main.css";

function App() {
  return (
    <Router future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
      <AuthProvider>
        <div className="omnicalendar">
          <Routes>
            <Route path="/" element={<Login />} />

            <Route
              path="/holding"
              element={
                <ProtectedRoute>
                  <Holding />
                </ProtectedRoute>
              }
            />

            <Route path="/navigation-guide" element={
              <ProtectedRoute>
                <NavigationGuide />
              </ProtectedRoute>
              }
            />

            <Route path="/whs/:idSegment" element={
              <ProtectedRoute>
                <WHS />
              </ProtectedRoute>
              }
            />

            <Route path="/sla/:idSegment" element={
              <ProtectedRoute>
                <SLA />
              </ProtectedRoute>
              }
            />

            <Route path="/examples/:idSegment" element={
              <ProtectedRoute>
                <Examples />
              </ProtectedRoute>
              }
            />
            {/* Ruta 404: debe ir al final */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;
